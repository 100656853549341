<template>
    <div>
       <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">{{orderTypeData.order_type}}</span
                    >
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pr-3 comboModal" style="padding-right: 18px;max-height: 430px !important; overflow-x: hidden !important;overflow-y: scroll !important">
                        <div class="section mt-5">
                            <div class="col-md-12 mb-5 d-flex justify-content-between align-items-center" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Order Type Information</span>
                                <button class="btn btn-secondary btn-smm btnWhite" @click="editOrderTypeView(orderTypeData._id)">
                                  <i class="icon icon-pencil btnWhite fs-16 pr-1"></i>Edit</button>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="a-q-Jc-j-contactView">
                                        <div class="a-q-Jc-Pj-Ya-contactView">
                                            <div class="a-q-Jc-bj-contactView">
                                                Order Type
                                            </div>
                                            <div class="a-q-Jc-Lb-contactView">
                                                <span class="a-q-Jc-wg-contactView">
                                                    {{orderTypeData.order_type}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="a-q-Jc-j-contactView">
                                        <div class="a-q-Jc-Pj-Ya-contactView">
                                            <div class="a-q-Jc-bj-contactView">
                                                {{orderTypeData.description}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section mt-5">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Timings</span>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="contact-item row no-gutters align-items-center multiple-heading py-sm-4 pl-3" style="background:#00448b !important;">
                                        <div class="col multiple-heading-div">Day</div>
                                        <div class="col multiple-heading-div">Opening Hours</div>
                                        <div class="col multiple-heading-div">Closing Hours</div>
                                    </div>
                                    <div class="add-member-modal pl-3">
                                        <div class="contact-item row no-gutters align-items-center py-sm-4 d-flex justify-content-between" v-for="(time,index) in orderTypeData.timings" :key="index">
                                            <div class="col text-truncate font-weight-bold product-name">{{time.day}}</div>
                                            <div class="col text-truncate">
                                                {{time.opening_hours || '-'}}
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                {{time.closing_hours || '-'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <a class="btn btn-danger btn-smm text-white" @click="cancel()">CANCEL</a>
                </div>
            </div>
       </modal>
       <order-type-edit modal_name="order-type-edit" :source="source"></order-type-edit>
    </div>
</template>

<script>
import { EventBus } from './eventBus/nav-bar-event'
import OrderTypeEdit from './EditOrderType'
export default {
    props: ['modal_name'],
    data(){
        return{
            orderTypeData:'',
            source:''
        }
    },
    components:{
        OrderTypeEdit
    },
    methods:{
        cancel(){
            // this.clearAllData()
            this.$modal.hide(this.modal_name)
        },
        editOrderTypeView(id){
            let params ={
                _id:id
            }
            this.source = 'viewOrderTypeEdit'
            EventBus.$emit('editOrderType',params)
            this.$modal.show('order-type-edit')
        },
    },
    mounted(){
        EventBus.$on('showOrderType',params=>{
            this.$http.post('/order_type/get_order_type_by_id',params).then(res=>{
                this.orderTypeData = res.data.response
            })
        })
        EventBus.$on('showOrderTypeEdit',params=>{
            this.$http.post('/order_type/get_order_type_by_id',params).then(res=>{
                this.orderTypeData = res.data.response
            })
        })
    }
}
</script>
<style scoped>

.section {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.btnWhite {
  background-color: #fff !important;
  color: #00448b !important;
}
.a-q-Jc-Pj-Ya-contactView {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    line-height: 30px;
}

.a-q-Jc-Pj-Ya-contactView {
    line-height: 25px;
    padding: 16px 10px 16px 3px;
    border-bottom: solid 1px #9e9e9e69;
}

.a-q-Jc-bj-contactView {
    -webkit-flex: 0 0 190px;
    flex: 0 0 190px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303031;
    margin-left: 13px;
}

.a-q-Jc-Lb-contactView {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-decoration: none;
    line-height: 1.21;
    letter-spacing: normal;
    color: #303031;
    margin-left: 88px;
}

.a-q-Jc-Pj-Ya-contactView:nth-of-type(even) {
    background-color: #f5f6f9;
}
.a-q-Jc-wg-contactView {
    text-decoration: none;
}
</style>